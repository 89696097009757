import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getInstructions } from "../../api/Registration";
import { APIError } from "../../api/ObservableFromFetch";
import { StepProps } from "./Barcode";
import InstructionResponse from "../../api/models/registration/InstructionResponse";
import InstructionModel from "../../api/models/registration/Instruction";
import { sanitize } from "../../formatters/Sanitize";

interface InstructionProps extends StepProps {
  stepNumber: number
  collectionType?: string
  implementation?: string
}

export default function Instruction(props: InstructionProps) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [instructions, setInstructions] = useState<InstructionModel[]>([])
  const [video, setVideo] = useState<string | undefined>(undefined)
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [showInstructions, setShowInstructions] = useState(false)
  const [showVideo, setShowVideo] = useState<boolean>(false)

  const { collectionType, implementation } = props

  useEffect(() => {
    const subscription = getInstructions({ collectionType, implementation }).subscribe({
      next: (result: InstructionResponse) => {
        setInstructions(result.instructions)
        setVideo(result.video)
        setLoading(false)
      },
      error: (e: APIError) => {
        setLoading(false)
        toast.error(t(e.message, { data: e.data }))
      }
    })
    return () => subscription.unsubscribe()
  }, [collectionType, implementation])

  const steps = instructions.filter(i => i.step === currentStep)
  const finalStep = Math.max(...instructions.map(i => i.step))

  const handleVideoClick = () => {
    setShowVideo((prev) => !prev)
  }

  return (
    <>
      <Row>
        <Col><h2 className="text-primary">{props.title}</h2></Col>
      </Row>
      {loading && <Spinner animation="border" role="status" size="sm" className="mx-1" />}
      <Row>
        <Col sm={12}>
          <div className="d-flex justify-content-center instruction-steps">
            {instructions.filter(i => i.headerRow).map((i, index) =>
              <div key={`header-step-${index}`} className="px-3">
                <div className={`step ${currentStep === i.step ? "current" : currentStep > i.step ? "done" : ""}`} data-step={index + 1}>{props.stepNumber}{String.fromCharCode(65 + index)}</div>
                <div style={{ display: "table-cell" }}>{i.header}</div>
              </div>
            )}
          </div>
          {steps.map(s => <div key={`step-${s.instructionId}`} className="m-1">
            <Row className="mt-05">
              <Col className={`${s.headerRow ? "text-primary" : "ps-5"}`}><div dangerouslySetInnerHTML={{ __html: sanitize(s.body) }} /></Col>
              <Col md={4} sm={5}>{s.image && <img src={s.image.replace("~", "")} />}</Col>
            </Row>
          </div>)}
        </Col>
      </Row>
      {showVideo && <Row>
        <Col lg={10} className="text-center">
          <div className="video-container">
            <iframe className="youtube-video"
              width="100%" height="100%" src={`${video}?enablejsapi=1`}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen frameBorder={0} />
          </div>
        </Col>
      </Row>}
      {!loading && <Row>
        <Col sm={3} className="my-3">
          {video && <Button onClick={handleVideoClick}>{t("registration.button.watchVideo")}</Button>}
        </Col>
        <Col sm={6} className="text-center px-0 my-3">
          {currentStep > 1 && <Button className="mx-2" onClick={() => setCurrentStep(currentStep - 1)}>{t("registration.button.back")}</Button>}
          {currentStep < finalStep && <Button className="mx-2" onClick={() => setCurrentStep(currentStep + 1)}>{t("registration.button.continue")}</Button>}
          {currentStep === finalStep && <Button variant="primary" type="submit" onClick={() => props.onNext && props.onNext()}>{t("registration.button.next")}</Button>}
        </Col>
        <Col sm={3} className="my-3"><Button onClick={() => setShowInstructions(!showInstructions)}>{t("registration.button.instructions")}</Button></Col>
      </Row>}
      {showInstructions && <Row>
        <Col className="my-3">
          {instructions.map(s => <div key={`instruction-${s.instructionId}`} className="m-1">
            <Row className="mt-05">
              <Col className={`${s.headerRow ? "text-primary" : "ps-5"}`}><div dangerouslySetInnerHTML={{ __html: sanitize(s.body) }} /></Col>
            </Row>
          </div>)}
          <Button variant="primary" type="submit" onClick={() => props.onNext && props.onNext()}>{t("registration.button.next")}</Button>
        </Col>
      </Row>}
    </>
  )
}
