import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getDomainInfo } from '../api/DomainInfo';
import { getDonorInfo } from '../api/DonorInfo';
import Domain from '../api/models/Domain';
import Donor from '../api/models/Donor';
import { useAuthStore } from '../stores/StoreProvider';
import { Footer } from './Layout/Footer';
import { Header } from './Layout/Header';
import LoadingOverlay from './LoadingOverlay';
import IdleTimer from "./IdleTimer"
import { useLocation } from 'react-router';
import { AppRoute } from '../AppRoute';

interface LayoutProps {
  loading?: boolean
}

export function Layout(props: React.PropsWithChildren<LayoutProps>) {
  const { t } = useTranslation()
  const authStore = useAuthStore()
  const prevAuthToken = useRef<string>("")
  const showNav = !!authStore.authToken
  const domain = authStore.domain

  React.useEffect(() => {
    const subscription = getDomainInfo().subscribe({
      next: (result: Domain) => authStore.setDomain(result),
      error: (e) => toast.error(t(e.message, { data: e.data }))
    })
    return () => subscription.unsubscribe()
  }, [])

  React.useEffect(() => {
    if (authStore.authToken && !prevAuthToken.current) {
      getDonorInfo().subscribe({
        next: (result: Donor) => authStore.setDonorInfo(result),
        error: (e) => toast.error(t(e.message, { data: e.data }))
      })
      return () => {
        prevAuthToken.current = authStore.authToken
      }
    }
  }, [authStore.authToken])

  const location = useLocation()
  const showLinks = location.pathname === AppRoute.Registration || location.pathname === AppRoute.RegistrationAlt

  return (
    <>
      <Header nav={showNav && !showLinks} logo={showNav ? authStore.domain?.headerLogo : authStore.domain?.loginLogo} banner={authStore.domain?.homePageBanner} showLinks={showLinks} />
      <main>
        {!!authStore.authToken && <IdleTimer />}
        {domain && props.children}
      </main>
      <Footer />
      {props.loading && <LoadingOverlay />}
    </>
  )
}

export default observer(Layout)
