import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import ScanBarcodeIcon from "../../assets/images/ScanBarcodeIcon.png"
import { BarcodeScanner } from "./Modals/BarcodeScanner"
import { toast } from "react-toastify"
import { useTranslationWithRef } from "../../hooks/useTranslationWithRef"
import { BLUESTONE, HEALTH_CONFIRM, Implementation, WELLNESS } from "../../api/models/registration/Implementation"

export interface StepProps {
  title?: string
  onPrev?: () => void
  onNext?: () => void
}

export interface BarcodeStepProps {
  title?: string
  onNext: (barcode: string, scanned: boolean) => void
  implementation?: Implementation
  useTwelve: boolean
  submitting: boolean
}

export default function Barcode(props: BarcodeStepProps) {
  const [translationRef, t] = useTranslationWithRef()
  const [barcode, setBarcode] = useState<string>("")
  const [scanned, setScannded] = useState<boolean>(false)
  const [barcodeHidden, setBarcodeHidden] = useState<boolean>(false)
  const [secondBarcode, setSecondBarcode] = useState<string>("")
  const [displaySecondBarcode, setDisplaySecondBarcode] = useState<boolean>(false)
  const barcodeScanner = useRef<BarcodeScanner | undefined>(undefined)
  const [cameraNotAvailable, setCameraNotAvailable] = useState<boolean>(false)
  const [barcodeValid, setBarcodeValid] = useState<boolean>(true)

  const barcodeRef = useRef<HTMLInputElement>(null)
  const secondBarcodeRef = useRef<HTMLInputElement>(null)
  const submittingRef = useRef<boolean>(false)

  useEffect(() => {
    const setScanedBarcode = (value: string) => {
      setScannded(true)
      setBarcode(value)
      setDisplaySecondBarcode(false)
    }

    const handleCameraNotAvailable = () => {
      setCameraNotAvailable(true)
    }

    barcodeScanner.current = new BarcodeScanner(setScanedBarcode, handleCameraNotAvailable, translationRef.current("registration.button.cancel"))
    return () => {
      if (barcodeScanner.current) {
        barcodeScanner.current.removeLayout()
      }
    }
  }, [translationRef])

  useEffect(() => {
    if (cameraNotAvailable) {
      toast.error(translationRef.current("registration.barcode.noCameraCouldBeFound"))
    }
  }, [translationRef, cameraNotAvailable])

  useEffect(() => {
    if (submittingRef.current && !props.submitting) {
      clearBarcodes()
    }

    submittingRef.current = props.submitting
  }, [props.submitting])

  const handleScan = () => {
    setBarcode("")
    handleBarcodeFocus()
    if (barcodeScanner.current) {
      barcodeScanner.current.cameraStart()
    }
  }

  const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBarcodeValid(true)
    setBarcode(e.target.value)
    setScannded(false)
    setDisplaySecondBarcode(false)
  }

  const handleBarcodeBlur = () => {
    if (!scanned && barcode !== "") {
      validateBarcode()
      setDisplaySecondBarcode(true)
      setBarcodeHidden(true)
      setTimeout(() => { secondBarcodeRef.current?.focus() }, 100)
    }
  }

  const handleBarcodeFocus = () => {
    setBarcodeHidden(false)
    setSecondBarcode("")
    setDisplaySecondBarcode(false)
  }

  const handleSecondBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecondBarcode(e.target.value)
  }

  const handleSecondBarcodeBlur = () => {
    validateBarcodes()
  }

  const validateBarcode = () => {
    var valid = (/^60\d{8}\d{0,2}|^61\d{8}\d{0,2}$/i).test(barcode)
    setBarcodeValid(valid)
    return valid
  }

  const clearBarcodes = () => {
    setBarcode("")
    setSecondBarcode("")
    setScannded(false)
    setBarcodeValid(true)
    setDisplaySecondBarcode(false)
    setTimeout(() => {
      barcodeRef.current?.focus()
    }, 100);
  }

  const validateBarcodes = () => {
    if (scanned) {
      return true
    }

    if (!displaySecondBarcode) {
      handleBarcodeBlur()
      return false
    }

    if (barcode !== secondBarcode) {
      toast.error(t("registration.barcode.valuesYouHaveEnteredDoNotMatch"))
      clearBarcodes()
      return false
    }
    return true
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (props.submitting) {
      return
    }

    const valid = validateBarcode()
    if (validateBarcodes() && valid) {
      props.onNext(barcode, scanned)
    }
  }

  const preventCopyPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
  }

  const preventContextMenu = (e: React.MouseEvent) => {
    e.preventDefault()
  }

  const scanningImageSrc = useMemo(() => {
    if (props.implementation && [BLUESTONE, HEALTH_CONFIRM, WELLNESS].includes(props.implementation)) {
      return `${process.env.PUBLIC_URL}/images/Scanning_Phone_${props.implementation}.png`
    }
    return `${process.env.PUBLIC_URL}/images/Scanning_Phone.png`
  }, [props.implementation])

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col><h2 className="text-primary">{props.title}</h2></Col>
      </Row>
      <Row>
        <Col>
          <Row><Col className="fw-bold mb-3">{t("registration.barcode.enterTheBarcodeValue")}</Col></Row>
          <Row><Col className="fw-bold">{t([`registration.barcode.pleaseEnsure_${props.implementation}`, `registration.barcode.pleaseEnsureThePatientsName`])}</Col></Row>
          <Row>
            <Col lg={4} md={6} sm={8} xs={8}>
              <Row className="mt-3">
                <Col sm={10} xs={12}>
                  <InputGroup>
                    <Form.Control type={barcodeHidden ? "password" : "text"} id="barcode1" ref={barcodeRef} value={barcode}
                      autoComplete="off" maxLength={12}
                      onChange={handleBarcodeChange} onBlur={handleBarcodeBlur} onFocus={handleBarcodeFocus}
                      onCopy={preventCopyPaste} onPaste={preventCopyPaste} onCut={preventCopyPaste} onContextMenu={preventContextMenu} />
                    <InputGroup.Text>
                      {!cameraNotAvailable && <img src={ScanBarcodeIcon} alt={t("registration.barcode.scanBarcode")} height={20} width="auto" onClick={handleScan} />}
                    </InputGroup.Text>
                  </InputGroup>
                  {!barcodeValid && <Form.Control.Feedback className="d-block" type="invalid">{barcode ? t("registration.validation.barcode") : t("registration.validation.fieldRequired")}</Form.Control.Feedback>}
                </Col>
              </Row>
              {displaySecondBarcode && <Row className="mt-3">
                <Col sm={10} xs={12}>
                  <Form.Label className="fw-bold">{t("registration.barcode.pleaseReEnterTheBarcodeValue")}</Form.Label>
                  <Form.Control type="text" id="barcode2" ref={secondBarcodeRef} value={secondBarcode}
                    autoComplete="off" maxLength={12}
                    onChange={handleSecondBarcodeChange} onBlur={handleSecondBarcodeBlur}
                    onCopy={preventCopyPaste} onPaste={preventCopyPaste} onCut={preventCopyPaste} onContextMenu={preventContextMenu} />
                </Col>
              </Row>}
              {props.useTwelve && <div className="m-1">
                <Form.Label>{t("registration.barcode.invalidBarcodePleasescanEnterThe12DigitBarcode")}</Form.Label>
                <img src={`${process.env.PUBLIC_URL}/images/TwelveDigitBarcode.jpg`} alt={t("registration.barcode.scanBarcode")} />
              </div>}
              <div className="px-0 my-3">
                <Button variant="primary" type="submit" disabled={props.submitting}>
                  {props.submitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />}
                  <span>{t("registration.button.next")}</span>
                </Button>
              </div>
            </Col>
            <Col lg={2} md={3} sm={4} xs={4} className="my-3">
              <img src={scanningImageSrc} alt={t("registration.barcode.scanBarcode")} height={150} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}
